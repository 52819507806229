<template>
  <v-footer v-if="footerShow" app inset padless dense>
    <v-card class="secondary text-left" flat tile width="100%" height="40px">
      <v-layout wrap>
        <v-card-text class="white--text pa-3">
          <div style="display: flex; justify-content: space-between; align-items: center">
            <span>support@epacket.vn</span>
            <router-link style="color: white" :to="{name: 'ExtensionGuide'}" target="_blank">Download Extension</router-link>
            <span>zalo : 0988611833</span>
          </div>
        </v-card-text>
      </v-layout>
    </v-card>
  </v-footer>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
  name: 'TheLayoutFooter',
  components: {},
  data: () => ({
    icons: [
      'mdi-home',
      'mdi-email',
      'mdi-calendar',
      'mdi-delete',
    ],
  }),
  computed: {
    ...mapGetters({
      footerShow: 'settings/footerShow',
    }),
  },
  methods: {},
};
</script>
