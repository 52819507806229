/** When your routing table is too long, you can split it into small modules */

const authRouter = [
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/admin/auth/Login.vue'),
    hidden: true,
    props: route => ({
      email: route.params.email,
      password: route.params.password,
    })
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('@/admin/auth/Register.vue'),
    hidden: true,
  },
  {
    path: '/reminder/pass',
    name: 'ReminderPass',
    component: () => import('@/admin/auth/Reminder.vue'),
    hidden: true,
  },
  {
    path: '/pw/reminder/:token',
    name: 'ResetPass',
    component: () => import('@/admin/auth/ResetPass.vue'),
    hidden: true,
  },
];

export default authRouter;
