<template>
  <v-app>
    <router-view ref="content"/>
    <div id="app-loading" :style="appLoading || initializing ? 'display: block;' : 'display: none;'">
      <img class="indicator-icon" :src="require('~/img/loading_indicator.svg')" alt="loading...">
    </div>
    <AdminNotice></AdminNotice>
  </v-app>
</template>
<script>
import {mapActions, mapGetters} from 'vuex'
import AdminNotice from "@/admin/components/AdminNotice";

export default {
  name: 'Admin',
  data() {
    return {
      needUserMasterData: true,
      watchUserAuth: null,
      initializing: true,
    }
  },
  components: {
    AdminNotice
  },
  computed: {
    ...mapGetters({
      appLoading: 'system/appLoading',
      notices: 'system/notices',
    }),
    _user() {
      return this.$auth.user()
    },
  },
  methods: {
    ...mapActions({
      loadMasterData: 'loadMasterData',
      loadUserMasterData: 'loadUserMasterData',
      setAuthenticatedUser: 'user/setAuthenticatedUser'
    }),
    checkRedirectNewUI() {
      if (!window.localStorage.getItem('use_old_ui')) {
        window.localStorage.setItem('use_old_ui', '0')
      }
      if (window.localStorage.getItem('administrator_old_ui') === '3') return;
      // qua ngày 31 sẽ ko cho sử dụng UI cũ
      const targetDateString = '2024-08-31';
      // Chuyển đổi ngày mong muốn thành đối tượng Date
      const targetDate = new Date(targetDateString);
      const today = new Date();
      // Đặt giờ phút giây của cả hai ngày về 00:00:00 để chỉ so sánh ngày
      today.setHours(0, 0, 0, 0);
      targetDate.setHours(0, 0, 0, 0);
      const isPastTargetDate = today > targetDate;
      if (isPastTargetDate) {
        window.localStorage.setItem('use_old_ui', '0')
        window.location.replace(location.protocol + '//' + location.host + '/new-ui');
      }
      // end

      if (this.CONSTANTS.ROLE_USE_OLD_UI.includes(this.$auth.user().role_id)) {
        if (window.localStorage.getItem('use_old_ui') === '0') {
          const full = location.protocol + '//' + location.host + '/new-ui';
          window.location.replace(full);
        }
      } else {
        window.location.replace(location.protocol + '//' + location.host + '/new-ui');
      }
    }
  },
  watch: {
    '$route': {
      handler(to) {
        if (!to || !to.matched || to.matched.length < 1)
          return this.$router.push({
            name: 'Page404',
          });
      }
    },
  },
  created() {
    this.watchUserAuth = this.$watch('_user', (newVal) => {
      console.log('SET_AUTHENTICATED_USER', newVal)
      if (newVal && newVal.id && newVal.role_id) {
        // handle login new UI
        window.localStorage.setItem("userData", JSON.stringify(newVal));
        let userAbilityRules = '%5B%7B%22action%22%3A%22manage%22%2C%22subject%22%3A%22all%22%7D%5D'
        if (window.localStorage.getItem('default_auth_token')) {
          document.cookie = 'accessToken='+window.localStorage.getItem('default_auth_token')+'; expires=Sun, 1 Jan 2030 00:00:00 UTC; path=/'
          document.cookie = 'userAbilityRules='+userAbilityRules+'; expires=Sun, 1 Jan 2030 00:00:00 UTC; path=/'
        }
        // end
        this.setAuthenticatedUser(JSON.parse(JSON.stringify(newVal)))
        this.needUserMasterData = false
        const unwatch = this.$watch(
          () => this.$route,
          (route) => {
            this.activeIndex = route.name;
            if (route.name !== 'TrackingNumberHistory') this.checkRedirectNewUI()
            unwatch();
          });
        this.loadUserMasterData().then(res => {
          //console.log("User's master data :", res)
          if (this.$route.name === 'Login') {
            if (this.UserRole === this.CONSTANTS.ROLES.CUSTOMER || this.UserRole === this.CONSTANTS.ROLES.SUPERVISOR) return this.$router.push({name: 'ManageOrders'});
            return this.$router.push({name: 'Dashboard'});
          }
        })
      } else this.setAuthenticatedUser(null)
    });
    this.loadMasterData().then(res => {
      console.log("Master data :", res)
    });
  },
  updated() {
    if (!this.initializing) return;
    this.$nextTick(() => {
      if (typeof this.$refs.content != 'undefined') this.initializing = false
    })
  }
};
</script>

<style lang="scss" scoped>
#app-loading {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #1e1e1ebf;
  z-index: 999999;

  img.indicator-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

#app-loading.show {
  display: block !important;
}
</style>
<style>
@import './styles/custom.scss';

::-webkit-scrollbar {
  background: #eeeeeeee;
  border-radius: 0;
  height: 7px;
  transition: all 0.3s ease;
  width: 7px;
}

::-webkit-scrollbar:hover {
  background: #c6c6c6;
}

::-webkit-scrollbar-thumb {
  background: #88888888;
  border-radius: 3px;
}

html {
  overflow-y: auto !important;
}
</style>
