<template>
  <v-card class="mx-auto pa-5">
    <v-row>
      <v-col cols="12">
        <h3>Quý khách vui lòng chuyển khoản đến ngân hàng SHB.</h3>
        <h3>Số Tài khoản: <span class="red--text" style="letter-spacing: 0.75rem;">0826898015</span></h3>
        <h3>Chủ tài khoản: <span class="red--text text-uppercase">pham kim du</span></h3>
        <h3>Nội Dung tin nhắn : <span class="red--text">{{ prefix }} {{ code }}</span></h3>
        <h3>Quý khách lưu ý ghi <span class="red--text">CHÍNH XÁC</span> nội dung tin nhắn để hệ thống thực hiện tốt việc nạp tiền</h3>
        <div v-if="qrcode" class="text-center">
          <img style="width: 300px; height: 400px" :src="qrcode" alt/>
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
import {mapActions} from 'vuex'
import CONSTANTS from '@/config/constants'

export default {
  name: 'CreatePayment',
  props: ['onCreated', 'onCancel'],
  data: () => ({
    item: {},
    code: '',
    qrcode: '',
  }),
  computed: {
    prefix() {
      const listPrefix = CONSTANTS.LIST_PAYMENT_PREFIX;
      return listPrefix[Math.floor(Math.random() * listPrefix.length)];
    }
  },
  methods: {
    ...mapActions({
      createItem: 'payment/create',
      createVietQR: 'payment/createVietQR',
    }),
    processAdd() {
      this.createItem(this.item).then(res => {
        if (res.status === 200 && res.data && res.data.error === false) {
          this.code = res.data.data ? res.data.data.code : '';
          this.onCreateQR(this.prefix + ' ' + this.code)
        }
        if (this.onCreated) this.onCreated(res.data.data);
      });
    },
    async onCreateQR(message) {
      const res = await this.createVietQR(message)
      this.qrcode = null
      if (res && res.data && res.data.data) {
        this.qrcode = res.data.data.qrDataURL
      }
    }
  },
  mounted() {
    this.processAdd()
  }
}
</script>
<style scoped>
h3 {
  margin-bottom: 1rem;
}
</style>
