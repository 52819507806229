import {CONSTANTS} from "@/config";

export default [
  {
    name: 'ManageOrders',
    title: 'Quản Lý Order',
    icon: 'mdi-alpha-i',
    role: [CONSTANTS.ROLES.MANAGER],
  },
  {
    name: 'ManageBigContainers',
    title: 'Quản Lý Kiện Lớn',
    icon: 'mdi-alpha-i',
    role: [CONSTANTS.ROLES.MANAGER],
  },
  {
    name: 'ManageCustomers',
    title: 'Quản Lý Khách Hàng',
    icon: 'mdi-alpha-i',
    role: [CONSTANTS.ROLES.MANAGER],
  },
  {
    name: 'ManageCustomerRanks',
    title: 'Rank Khách Hàng',
    icon: 'mdi-alpha-i',
    role: [CONSTANTS.ROLES.MANAGER],
  },
  {
    name: 'ManageShippingPrices',
    title: 'Quản Lý Phí Vận Chuyển',
    icon: 'mdi-alpha-i',
    role: [CONSTANTS.ROLES.MANAGER],
  },
  {
    name: 'ManageServices',
    title: 'Danh Sách Dịch Vụ',
    icon: 'mdi-alpha-i',
    role: [CONSTANTS.ROLES.MANAGER],
  },
  {
    name: 'ManageOrdersyncs',
    title: 'Quản lý Order Sync',
    icon: 'mdi-alpha-i',
    role: [CONSTANTS.ROLES.MANAGER],
  },
  {
    title: 'Thống Kê',
    icon: 'mdi-alpha-i',
    role: [CONSTANTS.ROLES.MANAGER],
    children: [
      {
        name: 'ManageStatistics',
        title: 'Thống kê đơn hàng',
        icon: 'mdi-alpha-i',
        role: [CONSTANTS.ROLES.MANAGER],
      },
      {
        name: 'ManagePaymentSummaries',
        title: 'Hạch Toán Thu Chi',
        icon: 'mdi-alpha-i',
        role: [CONSTANTS.ROLES.MANAGER],
      },
    ]
  },
  {
    name: 'ManagePayments',
    title: 'Quản Lý Payment',
    icon: 'mdi-alpha-i',
    role: [CONSTANTS.ROLES.MANAGER],
  },
  {
    name: 'ImportLabels',
    title: 'Import Pdf Labels',
    icon: 'mdi-alpha-i',
    role: [CONSTANTS.ROLES.MANAGER],
  },
]
