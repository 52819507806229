import {CONSTANTS} from "@/config";

export default [
  {
    name: 'NewOrder',
    title: 'Tạo Mới Order',
    icon: 'mdi-alpha-i',
    role: [CONSTANTS.ROLES.CUSTOMER],
  },
  {
    name: 'ManageOrders',
    title: 'Danh Sách Order',
    icon: 'mdi-alpha-i',
    role: [CONSTANTS.ROLES.CUSTOMER],
  },
  {
    name: 'ManagePayments',
    title: 'Danh Sách Payment',
    icon: 'mdi-alpha-i',
    role: [CONSTANTS.ROLES.CUSTOMER],
  },
]
