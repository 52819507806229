import BaseServices from "@/admin/services/BaseServices";

let instance = null;

class OrderServices extends BaseServices {
  constructor(args) {
    if (!instance) {
      super(args)
      this.mapGetters({
        orderById: 'order/getById'
      });
      this.mapActions({
        getById: 'order/getById'
      })
      this.mapState(['order'])
      instance = this;
    }
    return instance
  }

  async getOrderById(orderId) {
    //test getter
    let order = await this.orderById(orderId);
    //test action
    if (!order) order = this.getById({id: orderId, params: {silent: true}});
    if (!order) return this.noticeError('Order not found')
    return order;
  }

  async allOrder() {
    console.log('test state allOrder:', this.order.all)
    return this.order.all
  }
}

export default OrderServices
