export default class BaseServices {
  constructor(args) {
    if (!window.Services) window.Services = {}
    window.Services[this.constructor.name] = this
    Object.keys(args).forEach(key => {
      this['$' + key] = args[key]
    });
    this.mapActions({
      noticeInfo: 'system/noticeInfo',
      noticeSuccess: 'system/noticeSuccess',
      noticeWarning: 'system/noticeWarning',
      noticeError: 'system/noticeError',
    })
  }

  mapGetters(mapping) {
    Object.keys(mapping).forEach(key => {
      this[key] = this.$store.getters[mapping[key]]
    })
  }

  mapActions(mapping) {
    Object.keys(mapping).forEach(key => {
      this[key] = (...args) => {
        let listPromise = [];
        this.$store._actions[mapping[key]].forEach(mapFunc => {
          listPromise.push(mapFunc(...args));
        });
        return Promise.all(listPromise);
      }
    })
  }

  mapMutations(mapping) {
    Object.keys(mapping).forEach(key => {
      this[key] = (...args) => {
        let listPromise = [];
        this.$store._mutations[mapping[key]].forEach(mapFunc => {
          listPromise.push(mapFunc(...args));
        });
        return Promise.all(listPromise);
      }
    })
  }

  mapState(listState) {
    listState.forEach(stateName => {
      this[stateName] = this.$store.state[stateName]
    })
  }
}
