import {CONSTANTS} from "@/config";

export default [
  {
    name: 'ManageOrders',
    title: 'Quản Lý Order',
    icon: 'mdi-alpha-i',
    role: [CONSTANTS.ROLES.PACKAGER],
  },
  {
    name: 'ManageBigContainers',
    title: 'Quản Lý Kiện Lớn',
    icon: 'mdi-alpha-i',
    role: [CONSTANTS.ROLES.PACKAGER],
  },
  {
    name: 'ImportLabels',
    title: 'Import Pdf Labels',
    icon: 'mdi-alpha-i',
    role: [CONSTANTS.ROLES.PACKAGER],
  },
]
