import {CONSTANTS} from "@/config";

export default [
  {
    name: 'ScanOrder',
    title: 'Scan & Nhận Hàng',
    icon: 'mdi-alpha-i',
    role: [CONSTANTS.ROLES.RECEIVER],
  },
  {
    name: 'ManageOrders',
    title: 'Quản Lý Order',
    icon: 'mdi-alpha-i',
    role: [CONSTANTS.ROLES.RECEIVER],
  }
]
