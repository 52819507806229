import {CONSTANTS} from "@/config";

export default [
  {
    name: 'ManageOrders',
    title: 'Quản Lý Order',
    icon: 'mdi-alpha-i',
    role: [CONSTANTS.ROLES.COLLABORATOR, CONSTANTS.ROLES.SALE],
  },
  {
    name: 'ManageCustomers',
    title: 'Quản Lý Khách Hàng',
    icon: 'mdi-alpha-i',
    role: [CONSTANTS.ROLES.COLLABORATOR, CONSTANTS.ROLES.SALE],
  },
  {
    name: 'ManagePayments',
    title: 'Quản Lý Payment',
    icon: 'mdi-alpha-i',
    role: [CONSTANTS.ROLES.SALE],
  },
]
