<template>
  <div style="display: inline-block">
    <div>
      <span class="user-balance">
        Balance:
        <money-format :value="userBalance" style="display: inline-block;"
                      :hide-subunits=true :locale="'vi'" :currency-code="'VND'">
      </money-format>
      </span>
      <v-btn small color="primary" @click="onClickMakePayment">
        <v-icon>mdi-currency-usd</v-icon>
        Nạp tiền
      </v-btn>
    </div>

    <v-dialog v-model="dialogAddPayment" :width="699">
      <CreatePayment v-if="dialogAddPayment" :onCancel="() => { dialogAddPayment = false}"></CreatePayment>
    </v-dialog>
  </div>
</template>
<script>
import CreatePayment from "@/admin/pages/ManagePayments/Create";
import MoneyFormat from 'vue-money-format'

export default {
  name: 'AppMakePayment',
  components: {
    CreatePayment,
    'money-format': MoneyFormat
  },
  data: () => ({
    dialogAddPayment: false
  }),
  computed: {
    userBalance() {
      return this.USER && this.USER.balance ? this.USER.balance : 0
    }
  },
  methods: {
    onClickMakePayment() {
      this.dialogAddPayment = true;
    }
  }
}
</script>
<style scoped lang="scss">
.user-balance {
  display: inline-block;
  margin-right: 1rem;
  font-size: 1.125rem;
  color: var(--v-error-darken3);
  font-weight: bold;
}
</style>
