import BaseServices from "@/admin/services/BaseServices";

let instance = null;

class NotificationServices extends BaseServices {
  $store = null
  constructor(args) {
    if (!instance) {
      super(args)
      instance = this;
    }
    return instance
  }

  async getAllNotifications() {

  }

  async pushNewNotification(message, type) {
    console.log(message, type)
  }

  async getUnread() {

  }

  async markAsRead(notification) {
    console.log(notification)
  }
}

export default NotificationServices
