export default [
  {
    path: '/manage-orders',
    component: () => import('@/admin/pages/ManageOrders'),
    name: 'ManageOrders',
  },
  {
    path: '/import-labels',
    component: () => import('@/admin/pages/ManageOrders/ImportLabels'),
    name: 'ImportLabels',
  },
  {
    path: '/new-order',
    component: () => import('@/admin/pages/ManageOrders/Create'),
    name: 'NewOrder',
  },
  {
    path: '/scan-order',
    component: () => import('@/admin/pages/ManageOrders/ScanOrder'),
    name: 'ScanOrder',
  }
]
